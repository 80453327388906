.final-container {
  min-height: 500px;
  display: grid;
  text-align: center;
}
.final-container .button-container {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 3rem;
}