.form-quote-container {
  background-color: #eeeeef;
  padding: 3rem 3rem;
  text-align: left;
}

.form-quote-container .error {
  font-size: 10px;
}

.form-quote-container .form-quote-title {
  color: #213450;
  font-size: 36px;
  line-height: 43px;
}

.form-quote-container .form-quote {
  padding-top: 2rem;
}

.form-quote-container .full-width-centered {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.form-quote-container .form-quote-button {
  justify-self: center;
  width: 70%;
  margin-top: 1rem;
}

.form-quote-container .full-width.form-subtitle {
  padding-bottom: 1rem;
  font-size: 20px;
  line-height: 24px;
  font-family: 'DIN Medium';
  color: #575756;
}

.form-quote-container .form-subtitle {
  color: #213450;
  font-size: 20px;
}

.form-quote-container input[type=date] {
  font-size: 16px;
}

/* Override CSS align left radios */
.form-quote-container .input-radio-wrapper  .radios-wrapper{
  justify-content: initial;
}
.form-quote-container .input-radio-wrapper  .radios-wrapper > .input-radio-label:not(:last-child){
  padding-right: 1rem;
}

@media (max-width: 991px) {
  .form-quote-container {
    padding: 0 2rem 2rem 2rem;
    text-align: center;
  }

  .form-quote-container .form-quote-button {
    width: 100%;
  }

  .form-quote-container .form-quote-title {
    position: relative;
    color: #213450;
    font-size: 20px;
    bottom: 55px;
    font-family: "DIN Bold";
  }

}
@media (max-width: 560px) {
  .form-quote-container .form-quote {
    display: initial;
  }

  .form-quote-container .form-quote .row-less-spacing {
    margin-right: -5px;
    margin-left: -5px;
  }

  .form-quote-container .form-quote .col-less-spacing {
    padding-right: 5px;
    padding-left: 5px;
  }
}