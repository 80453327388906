/* @font-face {
  font-family: 'DIN Medium';
  src: url(./assets/fonts/din-medium-regular.woff2) format('woff2'),
    url(./assets/fonts/din-medium-regular.woff) format('woff'),
    url(./assets/fonts/din-medium-regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Regular';
  src: url(./assets/fonts/din-regular.woff2) format('woff2'), url(./assets/fonts/din-regular.woff) format('woff'),
    url(./assets/fonts/din-regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Bold Regular';
  src: url(./assets/fonts/din-bold.woff2) format('woff2'), url(./assets/fonts/din-bold.woff) format('woff'),
    url(./assets/fonts/din-bold.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'DIN Regular',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #2c2c2c;
}

body::-webkit-scrollbar-thumb {
  background-color: #5C0F8B;
  border-radius: 5px;
}