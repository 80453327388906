.home-container {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  top: -70px;
}

.home-second-column {
  padding-top: 70px;
}

.full-width {
  grid-column-start: 1;
  grid-column-end: 3;
}

.home-container .subtitle-component-wrapper {
  padding: 3rem 0 3rem 3rem;
}

@media (max-width: 991px) {
  .home-container {
    display: block;
    position: relative;
    top: 0;
    padding: 0;
  }

  .home-container .subtitle-component-wrapper {
    display: none;
  }
}