
.personal-data-container {
  background-color: #eeeeef;
}

.personal-data-container .personal-data-subcontainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 2rem;
  padding-bottom: 2rem;
}

.personal-data-container .radios-personal-data {
  display: flex;
}

.personal-data-container .payment-form-title {
  color: #575756;
  text-align: left;
  font-size: 36px;
  padding-bottom: 2rem;
}

.personal-data-container .radio-group {
  display: grid;
  padding: 0.5rem 1rem;
  grid-template-rows: 1fr 1fr;
  background-color: white;
  border: 1px solid white;
}

.personal-data-container .person-type-combo {
  grid-column-start: 1;
  grid-column-end: 4;
}

.personal-data-container .condition-combo {
  grid-column-start: 4;
  grid-column-end: 7;
}

.personal-data-container .doc-input {
  grid-column-start: 7;
  grid-column-end: 10;
}

.personal-data-container .gender-input {
  grid-column-start: 10;
  grid-column-end: 13;
}

.personal-data-container .name-input {
  grid-column-start: 1;
  grid-column-end: 7;
}

.personal-data-container .last-name-input {
  grid-column-start: 7;
  grid-column-end: 13;
}

.personal-data-container .email-input {
  grid-column-start: 1;
  grid-column-end: 7;
}

.personal-data-container .prefix-input {
  grid-column-start: 7;
  grid-column-end: 9;
}

.personal-data-container .phone-input {
  grid-column-start: 9;
  grid-column-end: 13;
}

.personal-data-container .street-input {
  grid-column-start: 1;
  grid-column-end: 7;
}

.personal-data-container .street-number-input {
  grid-column-start: 7;
  grid-column-end: 9;
}

.personal-data-container .floor-input {
  grid-column-start: 9;
  grid-column-end: 11;
}

.personal-data-container .apt-number-input {
  grid-column-start: 11;
  grid-column-end: 13;
}

.personal-data-container .nationality-input {
  grid-column-start: 1;
  grid-column-end: 5;
}

.personal-data-container .birthday-input {
  grid-column-start: 5;
  grid-column-end: 9;
}

.personal-data-container .taker-activity-input {
  grid-column-start: 9;
  grid-column-end: 13;
}

.personal-data-container .zip-code-input {
  grid-column-start: 1;
  grid-column-end: 7;
}

.personal-data-container .locality-input {
  grid-column-start: 7;
  grid-column-end: 13;
}

/* mobile */
@media (max-width: 992px) {
  .personal-data-container .personal-data-subcontainer{
    grid-gap: 1rem;
    padding-bottom: 1rem;
  }

  
  .personal-data-container .payment-form-title {
    font-size: 20px;
    padding-bottom: 1rem;
  }

  .personal-data-container .person-type-combo {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  .personal-data-container .condition-combo {
    grid-column-start: 6;
    grid-column-end: 13;
  }

  .personal-data-container .doc-input {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  .personal-data-container .gender-input {
    grid-column-start: 6;
    grid-column-end: 13;
  }

  .personal-data-container .name-input {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  .personal-data-container .last-name-input {
    grid-column-start: 6;
    grid-column-end: 13;
  }

  .personal-data-container .email-input {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .personal-data-container .prefix-input {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .personal-data-container .phone-input {
    grid-column-start: 4;
    grid-column-end: 13;
  }

  .personal-data-container .nationality-input {
    grid-column-start: 1;
    grid-column-end: 13;
  }
  
  .personal-data-container .birthday-input {
    grid-column-start: 1;
    grid-column-end: 6;
  }
  
  .personal-data-container .taker-activity-input {
    grid-column-start: 6;
    grid-column-end: 13;
  }
  
}

@media (max-width: 550px) {

  .personal-data-container .person-type-combo,
  .personal-data-container .condition-combo,
  .personal-data-container .doc-input,
  .personal-data-container .gender-input,
  .personal-data-container .name-input,
  .personal-data-container .last-name-input,
  .personal-data-container .email-input,
  .personal-data-container .prefix-input,
  .personal-data-container .birthday-input,
  .personal-data-container .taker-activity-input,
  .personal-data-container .phone-input{
    grid-column-start: 1;
    grid-column-end: 13;
  }
  
}
