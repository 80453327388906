.property-data-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  padding-bottom: 2rem;
}

.property-data-container .disabled-input, .property-data-container .disabled-input input {
  cursor: not-allowed;
}

.property-data-container .security-check-input{
  grid-column-start: 1;
  grid-column-end: 4;
}

.property-data-container .taker-zip-code {
  grid-column-start: 1;
  grid-column-end: 4;
}

.property-data-container .taker-locality {
  grid-column-start: 4;
  grid-column-end: 7;
}

.property-data-container .taker-street {
  grid-column-start: 1;
  grid-column-end: 3;
}

.property-data-container .taker-street-number {
  grid-column-start: 3;
  grid-column-end: 4;
}

.property-data-container .taker-floor {
  grid-column-start: 4;
  grid-column-end: 5;
}

.property-data-container .taker-departmen {
  grid-column-start: 5;
  grid-column-end: 7;
}

.property-data-container .state-input {
  grid-column-start: 1;
  grid-column-end: 3;
}

.property-data-container .locality-input {
  grid-column-start: 3;
  grid-column-end: 5;
}

.property-data-container .date-input {
  grid-column-start: 5;
  grid-column-end: 7;
}

.property-data-container .street-input {
  grid-column-start: 1;
  grid-column-end: 3;
}

.property-data-container .street-number-input {
  grid-column-start: 3;
  grid-column-end: 4;
}

.property-data-container .floor-input {
  grid-column-start: 4;
  grid-column-end: 5;
}

.property-data-container .department-input {
  grid-column-start: 5;
  grid-column-end: 6;
}

.property-data-container .zip-code-input {
  grid-column-start: 6;
  grid-column-end: 7;
}

.property-data-container .same-place-check-input {
  grid-column-start: 1;
  grid-column-end: 4;
}

.property-data-container .fences-check-input {
  grid-column-start: 4;
  grid-column-end: 7;
}

.property-data-container .property-same-place-form-container {
  grid-column-start: 1;
  grid-column-end: 7;
}


/* mobile */
@media (max-width: 992px) {
  .property-data-container {
    grid-gap: 1rem;
    padding-bottom: 1rem;
  }
  
  .property-data-container .state-input {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .property-data-container .locality-input {
    grid-column-start: 4;
    grid-column-end: 7;
  }
  
  .property-data-container .date-input {
    grid-column-start: 1;
    grid-column-end: 7;
  }
  
  .property-data-container .street-input {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  
  .property-data-container .street-number-input {
    grid-column-start: 5;
    grid-column-end: 7;
  }
  
  .property-data-container .floor-input {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  
  .property-data-container .department-input {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  
  .property-data-container .zip-code-input {
    grid-column-start: 5;
    grid-column-end: 7;
  }
  
  .property-data-container .same-place-check-input {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .property-data-container .fences-check-input {
    grid-column-start: 1;
    grid-column-end: 7;
  }
  
  .property-data-container .taker-street {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  
  .property-data-container .taker-street-number {
    grid-column-start: 5;
    grid-column-end: 7;
  }
  
  .property-data-container .taker-floor {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  
  .property-data-container .taker-departmen {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .property-data-container .taker-zip-code {
    grid-column-start: 5;
    grid-column-end: 7;
  }
  
  .property-data-container .taker-locality {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .property-data-container .security-check-input{
    grid-column-start: 1;
    grid-column-end: 7;
  }
}

@media (max-width: 550px) {
  .property-data-container .floor-input {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .property-data-container .department-input {
    grid-column-start: 4;
    grid-column-end: 7;
  }
  
  .property-data-container .zip-code-input {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .property-data-container .taker-floor {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .property-data-container .taker-departmen {
    grid-column-start: 4;
    grid-column-end: 7;
  }

  .property-data-container .taker-zip-code {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .property-data-container .taker-locality {
    grid-column-start: 4;
    grid-column-end: 7;
  }
}

