.bg-page {
    background-color: #eeeeef;
}

.legaltxt {
    margin: 0;
    size: 14px;
    color: #6e6e6e;
    line-height: 17px;
    text-align: left;
    align-self: center;
    width: 80%;
}

.coverages-view-container {
    padding: 4rem;
}

.plan-name-title {
    text-align: left;
    font-family: "DIN Bold";
    font-size: 30px;
    padding-bottom: 2rem;
}

.plan-name-subtitle {
    text-align: left;
    padding-bottom: 4rem;
    color: #6e6e6e;
    font-size: 40px;
    font-weight: lighter;
}

.plan-name-adjust-clause {
    font-size: 20px;
}

.button-next {
    min-width: 18rem;
}


@media (max-width: 768px) {
    .legaltxt {
        font-size: 10px;
    }

    .coverages-view-container {
        padding: 2rem;
    }

    .plan-name-title {
        padding-bottom: 1rem;
        font-size: 20px;
    }

    .plan-name-subtitle {
        text-align: left;
        padding-bottom: 2rem;
        color: #213450;
        font-size: 20px;
        font-weight: lighter;
    }
}